export const menu = [
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Work",
    url: "/work",
  },
  {
    name: "Career",
    url: "/career",
  },
];
